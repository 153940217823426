<template>
  <a-modal
    v-bind="$attrs"
    v-on="$listeners"
    title="选择专家"
    okText="确定"
    cancelText="取消"
    @ok="ok"
    :width="865"
  >
    <div class="mb-20">
      <gf-re-input-search
        @search="change({ name: $event, current: 1 })"
      ></gf-re-input-search>
    </div>

    <div class="mb-20">
      <FilterSelect @change="change" :filterOptions="filterOptions" />
    </div>

    <div class="mb-20">
      <FilterRows :gutter="20" :span="12" :list="list" v-if="list">
        <div slot-scope="{ dataSource }" class="relative">
          <a-checkbox
            :value="dataSource.id"
            :checked="dataSource.id === expert"
            class="right-2.5 top-10 absolute"
          ></a-checkbox>
          <ExpertCard
            @click.native="select(dataSource.id)"
            v-bind:dataSource="dataSource"
          ></ExpertCard>
        </div>
      </FilterRows>
      <gf-empty v-else></gf-empty>
    </div>

    <div class="text-center">
      <a-pagination
        :pageSize="params.limit"
        :total="params.total"
        :current="params.current"
        @change="change({ current: $event })"
      ></a-pagination>
    </div>
  </a-modal>
</template>

<script>
import { getExpertList } from "@/api/api/expert";
import ExpertCard from "./components/card.vue";

export default {
  name: "SelectExpertModal",
  components: { ExpertCard },
  model: {
    prop: "visible",
    event: "change",
  },
  created() {
    this.change();
  },
  data() {
    return {
      params: {
        limit: 6,
        offset: 0,
        total: 0,
        current: 1,
        industry: "",
        field: "",
        service: "",
      },
      filterOptions: [
        {
          name: "擅长领域",
          flag: "ExpertField",

          restore: "field",
        },
        {
          name: "擅长行业",
          flag: "CompanyIndustry",

          restore: "industry",
        },
        {
          name: "擅长服务",
          flag: "ServiceClass",

          restore: "service",
        },
      ],
      list: [],
      expert: "",
    };
  },
  methods: {
    ok() {
      this.$emit("confirm", { expert_id: this.expert });
    },
    select(e) {
      this.expert = e;
    },
    change(e) {
      const params = this.generateParams(e);
      this.getExpertList(params);
    },
    generateParams(e) {
      const temp = Object.assign(this.params, e);

      return {
        offset: (temp.current - 1) * temp.limit,
        industry: temp.industry.toString(),
        field: temp.field.toString(),
        service: temp.service.toString(),
        limit: temp.limit,
        name: temp.name,
      };
    },
    getExpertList(params) {
      this.loading = true;
      getExpertList(params)
        .then(({ count, rows }) => {
          this.$set(this.params, "total", count);
          this.list = rows;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>