<template>
  <div>
    <div class="mb-40">
      <gf-title class="mb-20">资质要求</gf-title>
      <div
        class="mb-20"
        v-for="(item, index) in dataSource.demands"
        :key="index"
      >
        <div class="mb-10">{{ index + 1 }}.{{ item.Key }}</div>
        <a-radio-group v-model="item.Value">
          <a-radio :value="true" v-if="item.Value">是</a-radio>
          <a-radio :value="false" v-else>否</a-radio>
        </a-radio-group>
      </div>
    </div>

    <div class="mb-40">
      <gf-title class="mb-20">解决方案</gf-title>
      <div class="flex mb-20">
        <div class="text-999 mb-10">拟投入人员数量：</div>
        <div>{{ dataSource.people }}人</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">解决方案概述：</div>
        <div>{{ dataSource.introduction }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">资质要求说明：</div>
        <div>{{ dataSource.description }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">补充说明：</div>
        <div>{{ dataSource.supplement }}</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">附件：</div>
        <div v-if="dataSource.attachments">
          <div v-for="(item, index) in dataSource.attachments" :key="index">
            <div class="flex items-center">
              <a-icon type="link" class="mr-10" />
              <a :href="item.url" target="blank" class="hover:text-primary">{{
                item.name
              }}</a>
            </div>
          </div>
        </div>
        <div v-else>无</div>
      </div>
      <div class="mb-20">
        <div class="text-999 mb-10">单位意见：</div>
        <div>{{ dataSource.comment }}</div>
      </div>
    </div>
    <div class="mb-40" v-if="showCompany">
      <gf-title class="mb-20">服务商基本信息</gf-title>
      <div>
        <div class="mb-20 flex">
          <div class="text-999">单位名称（全称）：</div>
          <div>{{ dataSource.company.companyName || "无" }}</div>
        </div>
        <div class="mb-20 flex">
          <div class="text-999">注册地址：</div>
          <div>{{ dataSource.company.registerAddress || "无" }}</div>
        </div>
        <div class="mb-20 flex">
          <div class="text-999">单位地址：</div>
          <div>{{ dataSource.company.businessAddress || "无" }}</div>
        </div>
        <div class="mb-20 flex">
          <div class="w-1/2 flex">
            <div class="text-999">法定代表人：</div>
            <div>{{ dataSource.company.Representative || "无" }}</div>
          </div>
          <div class="w-1/2 flex">
            <div class="text-999">注册资本：</div>
            <div>
              {{
                dataSource.company.RegisterCapital
                  ? dataSource.company.RegisterCapital / 10000 + "万"
                  : "无"
              }}
            </div>
          </div>
        </div>
        <div class="mb-20 flex">
          <div class="w-1/2 flex">
            <div class="text-999">成立时间：</div>
            <div>
              {{ dataSource.company.RegisterAt | formatterTime("YYYY-MM-DD") }}
            </div>
          </div>
          <div class="w-1/2 flex">
            <div class="text-999">单位性质：</div>
            <div>
              {{
                dataSource.company.Property
                  ? dataSource.company.Property.Name
                  : "无"
              }}
            </div>
          </div>
        </div>
        <div class="mb-20 flex">
          <div class="w-1/2 flex">
            <div class="text-999">是否上市：</div>
            <div>{{ dataSource.company.Listed ? "已上市" : "未上市" }}</div>
          </div>
          <div class="w-1/2 flex">
            <div class="text-999">单位规模：</div>
            <div>
              {{
                dataSource.company.Scale ? dataSource.company.Scale.Name : "无"
              }}
            </div>
          </div>
        </div>
        <div class="mb-20 flex">
          <div class="text-999">科研人员占比：</div>
          <div>{{ dataSource.company.Resercher || "无" }}</div>
        </div>
        <div class="mb-20">
          <div class="text-999 mb-10">单位介绍：</div>
          <div>{{ dataSource.company.Team || "无" }}</div>
        </div>
      </div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20">负责人信息</gf-title>
      <div class="flex flex-wrap">
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">姓名：</div>
          <div>{{ dataSource.marketName }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">单位职务：</div>
          <div>{{ dataSource.marketPosition }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">Email：</div>
          <div>{{ dataSource.marketEmail }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">手机号码：</div>
          <div>{{ dataSource.marketPhone }}</div>
        </div>
      </div>
    </div>
    <div class="mb-40">
      <gf-title class="mb-20">联系人信息</gf-title>
      <div class="flex flex-wrap">
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">姓名：</div>
          <div>{{ dataSource.operatorName }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">单位职务：</div>
          <div>{{ dataSource.operatorPosition }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">Email：</div>
          <div>{{ dataSource.operatorEmail }}</div>
        </div>
        <div class="mb-10 flex w-1/2">
          <div class="text-999 mb-10">手机号码：</div>
          <div>{{ dataSource.operatorPhone }}</div>
        </div>
      </div>
    </div>
    <div class="mb-40" v-if="dataSource.feedback">
      <gf-title class="mb-20">专家审批意见</gf-title>
      <div>{{ dataSource.feedback }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    showCompany: {
      type: Boolean,
      default: false,
    },
  },
};
</script>