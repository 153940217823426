// 成功案例
import request from '@/utils/request1'
import { transformExpert } from '@/utils/transform/expert.js'

// 获取专家列表
export function getExpertList(params) {
    return request.get('/experts', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformExpert)
        }
        return Promise.resolve(temp)
    })
}