<template>
  <div class="p-10 rounded border-eee">
    <div class="relative flex">
      <div class="aboslute top-0 right-0"></div>
      <div class="mr-2.5 border-eee" style="width: 66px; height: 88px">
        <img class="w-full h-full object-contain" v-if="dataSource.photo" :src="dataSource.photo.url" alt="" />
      </div>
      <div style="width: calc(100% - 76px)">
        <div class="mb-10">
          <a-space class="text-999 text-sm">
            <span class="font-bold text-111 text-base">{{ dataSource.name }}</span>
            <span>|</span>
            <span>{{ dataSource.position }}</span>
          </a-space>
        </div>
        <div class="mb-10 text-xs text-111">
          {{ dataSource.companyName }}
        </div>
        <div>
          <gf-tag-group>
            <gf-tag
              :rows="1"
              v-for="(item, index) in dataSource.industry"
              :key="index"
            >
              {{ item.Name }}
            </gf-tag>
          </gf-tag-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      default: () => ({}),
      type: Object,
    },
  },
};
</script>