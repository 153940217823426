import {
    transformOptions,
    transformAttachment,
    transformStatus
} from './_util'

export function transformExpert(data) {
    const expert = data || {},
        detail = expert.Detail || {},
        company = expert.Company || {};

    const temp = {
        id: data.ID, // id
        bankAccount: detail.BankAccount, // 银行账号
        bankBranch: detail.BankBranch, // 支行名称
        bankName: detail.BankName, // 银行名称
        department: detail.Department, // 部门
        desc: detail.Desc, // 个人成果
        email: detail.Email, // 邮箱
        gender: detail.Gender, // 性别
        identity: detail.Identity, // 身份证号
        name: detail.Name, // 姓名
        phone: detail.Phone, // 电话
        position: detail.Position, // 职位
        title: detail.Title, // 职称
        industry: transformOptions(detail.Industries, 'CompanyIndustry') || [], // 行业
        field: transformOptions(detail.Fields, 'ExpertField') || [], // 领域
        // honors: transformOptions(detail.Honors, [{ flag: 'Level', options: 'HonorLevel' }, { flag: 'Class', options: 'HonorClass' }]) || [], // 荣誉
        education: transformOptions(detail.Education, 'Education') || [], // 教育
        political: transformOptions(detail.Political, 'Political') || [], // 政治面貌
        service: transformOptions(detail.Services, 'ServiceClass') || [], // 服务
        tags: detail.Tag || [], // 个人标签
        companyName: company.Name, // 公司名称
        photo: transformAttachment(detail.Photo) || {}, // 专家图像
    }

    return temp;
}


export function restoreExpert() {

    return {
        BankAccount: "string",
        BankBranch: "string",
        BankName: "string",
        Department: "string",
        Desc: "string",
        Education: {
            "ID": 0,
            "Name": "string"
        },
        Email: "string",
        Fields: [
            {
                "ID": 0,
                "Name": "string"
            }
        ],
        Gender: 0,
        Honors: [
            {
                "Class": 0,
                "ClassName": "string",
                "Level": 0,
                "LevelName": "string",
                "Name": "string"
            }
        ],
        Identity: "string",
        Industries: [
            {
                "ID": 0,
                "Name": "string"
            }
        ],
        Name: "string",
        Phone: "string",
        Photo: {
            "ID": "string",
            "Name": "string"
        },
        Political: {
            "ID": 0,
            "Name": "string"
        },
        Position: "string",
        Services: [
            {
                "ID": 0,
                "Name": "string"
            }
        ],
        Tags: [
            "string"
        ],
        Title: "string",
    }
}